.bg-trong-dong {

    .swiper-home {
      height: 600px;
      display: flex;
      align-items: center;
      
      .swiper-slide {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      
      .swiper-slide:nth-child(1) {
        background-image: url('../../assets/images/swiper-home-bg-1.jpeg');
      }
      .swiper-slide:nth-child(2) {
        background-image: url('../../assets/images/swiper-home-bg-2.jpeg');
      }
      .swiper-slide:nth-child(3) {
        background-image: url('../../assets/images/swiper-home-bg-3.jpeg');
      }
      .swiper-slide:nth-child(4) {
        background-image: url('../../assets/images/swiper-home-bg-4.jpeg');
      }
      .swiper-slide:nth-child(5) {
        background-image: url('../../assets/images/swiper-home-bg.jpeg');
      }
      
      .item-swiper {
        width: 60%;
        
        .item-swiper__title {
          font-size: 28px;
          font-weight: 900;
          text-transform: uppercase;
          font-style: italic;
          color: #7B7B7B;
          margin-bottom: 20px;
          
          span {
            color: #ffc600;
          }
        }
      }
      
      .swiper-pagination .swiper-pagination-bullet {
        &.swiper-pagination-bullet-active {
          background-color: #FED780;
          border-radius: 50px;
          width: 50px;
          box-shadow: 0px 4px 15px 0px #A081294D;
          margin-right: 16px;
        }
        
        background-color: #7E7E7E;
        transition: 0.3s;
        width: 24px;
        height: 24px;
        opacity: 1;
        box-shadow: 0px 5px 15px 0px #00000033;
        margin-right: 16px;
      }
    }
    
    .info-col {
      color: #8E8E8E80;
      box-shadow: 0px 4px 50px 0px #9C9C9C33;
      backdrop-filter: blur(11.8px);
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      width: 100%;
      display: flex;
      background-color: #8E8E8E80;
      
      .info-col__item {
        width: 33.33%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        margin: 30px 0;
        
        .item-num {
          font-size: 42px;
          font-weight: 700;
        }
        
        .item-text {
          font-size: 24px;
        }
        
        &:not(:last-child) {
          border-right: 1px solid #fff;
        }
      }
    }
  }
  

.block-img {
    min-height: 375px;
    img {
        width: 100%;
    }
}

.video-guidelines {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 20px;
    padding: 0;
    gap: 20px;
    background-color: transparent;
}

.video-guidelines .video-section {
    flex: 2;
    iframe {
        width: 100%;
        /* min-height: 400px; */
        border-radius: 15px;
    }
}

.video-guidelines .text-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
    font-size: 16px;
    line-height: 1.5;
    color: #333;

    h3 {
        font-size: 22px;
        margin: 0 0 10px 0;
        color: #000;
    }

    p {
        margin: 0;
    }
}

main {
    margin-top: 0;
}

.home-container {
    position: relative;

    .bg-trong-dong-big {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;

        img {
            width: 500px;
            height: 1000px;
        }
    }
}

.bg-trong-dong-color {
    background: linear-gradient(to right, #fdfdfd, #f3f3f3);
}

@media screen and (max-width: 768px) {
    .item-swiper__title{
        font-size: 19px;
        font-weight: bold;
        font-style: italic;
    }
    .bg-trong-dong {
        background-size: contain;
        background-repeat: no-repeat;

        & .swiper-home {
            min-height: 380px;

            .item-swiper {
                width: 80%;
            }
        }

        & .info-col {
            & .info-col__item {
                .item-num {
                    font-size: 25px;
                }
                .item-text{
                    font-size: 20px;
                }
            }
        }
    }

    .video-guidelines {
        iframe {
            /* min-height: 350px; */
        }
    }
}
/* Container */
.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2vw;
    position: relative;
    margin-top: 64px;
}

/* Grid item */
.grid-item {
    background-color: #FED780;
    aspect-ratio: 1 / 1; /* Giữ hình vuông */
    font-size: 24px;
    font-weight: bold;
    border-radius: 24px;
    position: relative;
    overflow: hidden;
}

/* Định vị từng phần của ảnh trong 4 góc */
.grid-item:nth-child(1)::before, /* Góc trên trái */
.grid-item:nth-child(2)::before, /* Góc trên phải */
.grid-item:nth-child(4)::before, /* Góc dưới trái */
.grid-item:nth-child(5)::before { /* Góc dưới phải */
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/grid.png');
    background-size: 200% 200%;
    background-position: top left;
    border-radius: inherit;
}

/* Điều chỉnh vị trí từng góc */
.grid-item:nth-child(1)::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/image15.jpeg');
    background-size: cover;
    background-position: center;
    border-radius: inherit;
}
.grid-item:nth-child(2)::before {
    position: absolute;
    background-image: url('../../assets/images/image10.jpeg');
    background-size: cover;
    background-position: center;
}

.grid-item:nth-child(4)::before {
    /*filter: blur(7px); Làm mờ */
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/image3.jpeg');
    background-size: cover;
    background-position: center;
    border-radius: inherit;
}

.grid-item:nth-child(5)::before {
    background-position: bottom right;
    background-image: url('../../assets/images/image1.jpeg');
    background-size: cover;
    background-position: center;
}
.grid-item:nth-child(4)::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: #FED15740; Màu phủ */
    border-radius: inherit;
}
.grid-item:nth-child(3)::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/image5.jpeg');
    background-size: cover;
    background-position: center;
    border-radius: inherit;
}
.grid-item:nth-child(6)::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/image6.jpeg');
    background-size: cover;
    background-position: center;
    border-radius: inherit;
}
.grid-item:nth-child(7)::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/cup.png');
    background-size: cover;
    background-position: center;
    border-radius: inherit;
}
.grid-item:nth-child(8)::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/image17.jpeg');
    background-size: cover;
    background-position: center;
    border-radius: inherit;
}
.grid-item:nth-child(9)::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/image4.jpeg');
    background-size: cover;
    background-position: center;
    border-radius: inherit;
}
.flex-container {
    z-index: 1;
    height: -webkit-fill-available;
    padding: 2vw;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: space-between
}
.title {
    font-weight: 800;
    font-style: italic;
    font-size: 3vw; /* 3% chiều rộng màn hình */
    line-height: 4vw;
    color: #fff;
    margin: 0;

}

.description {
    font-weight: 500;
    font-size: 1.5vw; /* 1.5% chiều rộng màn hình */
    color: #fff;
}
.football-icon {
    width: 6vw !important;
    height: 6vw !important;
}
.football {
    display: flex;
    justify-content: flex-end;
}
.text-black {
    color: #5C5C5C !important;
}
@media (max-width: 768px) {
    .title {
        font-size: 20px; /* 3% chiều rộng màn hình */
        line-height: 32px;
        color: #fff;
    }
    
    .description {
        font-size: 12px; /* 1.5% chiều rộng màn hình */
        color: #fff;
    }
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
        padding-left: 10px;
        padding-right: 10px;
        width: -webkit-fill-available;
    }
    .grid-item:nth-child(1)::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/image1.jpeg');
        background-size: cover;
        background-position: center;
        border-radius: inherit;
    }
    .grid-item:nth-child(2)::before {
        position: absolute;
        background-image: url('../../assets/images/image2.jpeg');
        background-size: cover;
        background-position: center;
    }
    
    .grid-item:nth-child(4)::before {
        /*filter: blur(7px); Làm mờ */
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/image4.jpeg');
        background-size: cover;
        background-position: center;
        border-radius: inherit;
    }
    
    .grid-item:nth-child(5)::before {
        background-position: bottom right;
        background-image: url('../../assets/images/image5.jpeg');
        background-size: cover;
        background-position: center;
    }
    .grid-item:nth-child(4)::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }
    .grid-item:nth-child(3)::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/image3.jpeg');
        background-size: cover;
        background-position: center;
        border-radius: inherit;
    }
    .grid-item:nth-child(6)::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/image6.jpeg');
        background-size: cover;
        background-position: center;
        border-radius: inherit;
    }
    .grid-item:nth-child(7)::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/cup.png');
        background-size: cover;
        background-position: center;
        border-radius: inherit;
    }
    .grid-item:nth-child(8)::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/image14.jpeg');
        background-size: cover;
        background-position: center;
        border-radius: inherit;
    }
    .grid-item:nth-child(9)::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/final.png');
        background-size: cover;
        background-position: center;
        border-radius: inherit;
    }
    .display-desktop {
        display: none !important;
    }
    .display-mobile {
        display: flex;
    }
   
}
@media (min-width: 769px) {
    .display-desktop {
        display: flex;
    }
    .display-mobile {
        display: none !important;
    }
}