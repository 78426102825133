.block-news {
    margin-top: 30px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .news-title {
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;

        .news-title__btn {
        }

        .news-title__title {
            font-size: 40px;
            font-weight: 600;
            font-style: italic;
            color: #5c5c5c;
        }
    }

    .news-content {
        width: 100%;

        .news-content__main {
            width: 100%;
            display: flex;
            background-color: #fff;
            margin-bottom: 20px;
            border-radius: 16px;
            box-shadow:0px 4px 50px 0px #9C9C9C33;

            .main-img {
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
                width: 100%;
                height: 400px;
                background-color: #FED780;

                img {
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: 15px;
                }
            }
        }

        .main-content {
            width: 100%;
            padding: 15px;

            .content-title {
                font-size: 20px;
                line-height: 24px;
                font-weight: 700;
                color: #777777;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
            }

            .content-text {
                font-size: 16px;
                color: #7B7B7B;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
            }

            .content-more-info {
                margin-top: auto;
                display: flex;
                justify-content: space-between;

                .content-more-info__view {
                    color: #A2A2A2;
                    font-size: 14px;
                }

                .content-more-info__date {
                    color: #A2A2A2;
                    font-size: 14px;
                }
            }

            .divider-line {
                width: 62px;
                height: 2px;
                border-radius: 20px;
                background-color: #9D9D9D;
            }
        }

        .news-content__sub {
            display: flex;
            gap: 20px;

            .sub-item {
                width: 100%;
                background-color: #fff;
                flex-direction: column;
                display: flex;
                box-shadow:0px 4px 50px 0px #9C9C9C33;
                border-radius: 15px;
            }

            .sub-img {
                background-color: #FED780;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                width: 100%;
                height: 200px;

                img {
                    border-top-left-radius: 15px;
                    border-top-right-radius: 15px;
                }
            }
        }
    }
}
.custom strong {
    font-weight: 500;
}
.custom em {
    font-style: normal;
}
.custom h2 {
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
}
.custom h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}
@media screen and (max-width: 1200px) {
    .block-news {
        padding-left: 10px;
        padding-right: 10px;
        & .news-content {
            & .news-content__sub {
                .sub-item {
                    &:last-child {
                        display: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .block-news {
        & .news-content {
            & .news-content__sub {
                flex-direction: column;
                .sub-item {
                    &:last-child {
                        display: flex;
                    }
                }
            }
            .news-content__main {
                flex-direction: column;
                .main-img{
                    height: 200px;
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: unset;
                    border-top-right-radius: 15px;
                    img{
                        border-top-left-radius: 15px;
                        border-bottom-left-radius: unset;
                        border-top-right-radius: 15px;
                    }
                }
                .main-content{
                    box-sizing: border-box;
                }
            }
        }

        .news-title {
            .news-title__title{
                font-size: 30px;
                color: #5c5c5c;
            }
        }
    }
}