.single-news-content-container {
    margin-top: 40px;

    .single-news-content__title {
        color: #5C5C5C;
        font-size: 40px;
        font-weight: 600;
        font-style: italic;
    }

    .news-content__main {
        width: 100%;
        display: flex;
        margin-bottom: 20px;
        border-radius: 16px;
        margin-top: 30px;
        box-shadow:0px 4px 50px 0px #9C9C9C33;

        .main-img {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            width: 100%;
            height: 300px;
            background-color: #FED780;
        }

        .main-content {
            width: 100%;
            padding: 30px;

            .content-title {
                font-size: 20px;
                font-weight: 700;
                color: #777777;
            }

            .content-text {
                font-size: 16px;
                color: #7B7B7B;
                text-overflow: ellipsis;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
            }

            .content-more-info {
                margin-top: auto;

                .btn-dp {
                    background: unset;
                    box-shadow: unset;
                    border: 2px solid #5C5C5C
                }
            }

            .divider-line {
                width: 62px;
                height: 2px;
                border-radius: 20px;
                background-color: #9D9D9D;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .single-news-content-container {
        padding-left: 10px;
        padding-right: 10px;

        .news-content__main {
            flex-direction: column;

            .main-img {
                border-top-left-radius: 15px;
                border-bottom-left-radius: unset;
                border-top-right-radius: 15px;
            }

            .main-content {
                box-sizing: border-box;

                .content-text {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .single-news-content-container {

        .single-news-content__title {
            font-size: 30px;
        }}
}
