.bg-trong-dong {
    background-image: url('../../assets/images/swiper-home-bg.png');
    background-size: cover;
    background-position: center;

    .swiper-home {
        min-height: 600px;
        align-items: center;
        display: flex;

        .item-swiper {
            width: 60%;

            .item-swiper__title {
                font-size: 28px;
                font-weight: 900;
                text-transform: uppercase;
                font-style: italic;
                color: #7B7B7B;
                margin-bottom: 20px;
                font-weight: bold;
                font-style: italic;
                span {
                    color: #ffc600;
                }
            }
        }

        .swiper-pagination .swiper-pagination-bullet {
            &.swiper-pagination-bullet-active {
                background-color: #FED780;
                border-radius: 50px;
                width: 50px;
                gap: 16px;
                box-shadow: 0px 4px 15px 0px #A081294D;
            }

            background-color: #7E7E7E;
            transition: 0.3s;
            width: 24px;
            height: 24px;
            opacity: 1;
            box-shadow: 0px 5px 15px 0px #00000033;

        }
    }

    .info-col {
        color: #8E8E8E80;
        box-shadow: 0px 4px 50px 0px #9C9C9C33;

        backdrop-filter: blur(11.800000190734863px);
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 100%;
        display: flex;
        background-color: #8E8E8E80;

        .info-col__item {
            width: 33.33%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #fff;
            margin: 30px 0;

            .item-num {
                font-size: 42px;
                font-weight: 700;
            }

            .item-text {
                font-size: 24px;
            }


            &:not(:last-child) {
                border-right: 1px solid #fff;
            }
        }
    }
}

.dp-container-img {
    width: 100%;
    max-width: 3508px;
    margin: 0 auto;
}

.block-img {
    display: flex;
    flex-direction: column; /* Stacks images vertically */
    gap: 20px; /* Adds spacing between images */
}

.block-img img {
    width: 100%; /* Ensures images fit the container width */
    max-height: 4961px; /* Set a maximum height for each image */
    object-fit: contain; /* Ensures the aspect ratio is preserved */
    border-radius: 10px; /* Optional: Adds rounded corners */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Adds subtle shadow */
}


.video-guidelines {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 20px;
    padding: 0 10px;
    gap: 20px;
    background-color: transparent;
}

.video-guidelines .video-section {
    flex: 2;
    width: 100%;
    /* height: 100%; */
    position: relative;
}

.video-guidelines .video-section iframe {
    /* width: 100%;
    min-height: 400px; */
    border-radius: 15px;
    width: 100%;
    /* height: 100%; */
    border: none;
}

.video-guidelines .text-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
}

.video-guidelines .text-section h3 {
    font-size: 22px;
    margin: 0 0 10px 0;
    color: #000;
}

.video-guidelines .text-section p {
    margin: 0;
    align-content: right;
}

/* Responsive cho mobile */
@media (max-width: 768px) {
    .video-guidelines {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .video-guidelines .video-section {
        flex: none;
        width: 100%;
    }

    /* .video-guidelines .video-section iframe {
        min-height: 350px;
    } */

    .video-guidelines .text-section {
        flex: none;
        width: 100%;
        text-align: right;
    }
}


main {
    margin-top: 0;
}

.home-container {
    position: relative;

    .bg-trong-dong-big {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;

        img {
            width: 500px;
            height: 1000px;
        }
    }
}

.bg-trong-dong-color {
    background: linear-gradient(to right, #fdfdfd, #f3f3f3);
}

@media screen and (max-width: 768px) {
    .item-swiper__title{
        font-size: 19px;
        font-weight: bold;
        font-style: italic;
    }
    .bg-trong-dong {
        background-size: contain;
        background-repeat: no-repeat;

        & .swiper-home {
            min-height: 380px;

            .item-swiper {
                width: 80%;
            }
        }

        & .info-col {
            & .info-col__item {
                .item-num {
                    font-size: 25px;
                }
                .item-text{
                    font-size: 20px;
                }
            }
        }
    }

}