.block-videos {
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    
    .videos-title {
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;

        .videos-title__title {
            font-size: 40px;
            font-weight: 600;
            font-style: italic;
            color: #5c5c5c;
        }
    }
}

.videos-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    width: 1140px;
    max-width: 100%;
}

.video-item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
}

.video-item video {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
    object-fit: contain;
    border-radius: 16px;
}


@media screen and (max-width: 1200px) {
    .videos-content {
        grid-template-columns: repeat(3, 1fr);
        gap: 18px;
    }
}

@media screen and (max-width: 768px) {
    .videos-content {
        grid-template-columns: 1fr;
        gap: 18px;
    }
}

.video-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #555;
    font-size: 16px;
}

