.block-course {
    margin-top: 30px;
    box-sizing: border-box;

    .course-title {
        font-size: 40px;
        font-weight: 600;
        font-style: italic;
        color:#5c5c5c;
    }

    .course-content {
        margin-top: 30px;
        display: flex;
        gap: 20px;

        .course-content-item {
            box-sizing: border-box;
            display: flex;
            background-color: #fff;
            flex-direction: column;
            width: calc((100% - 40px) / 3);
            border: 1px solid #EFF0F6;
            box-shadow: 0px 4px 50px 0px #9C9C9C33;
            padding: 20px;
            border-radius: 24px;

            .top-icon {
                margin: 0 auto 10px;
                width: fit-content;
            }

            .course-content-item__name {
                font-size: 23px;
                font-weight: 700;
                text-align: center;
                margin-bottom: 15px;
                color: #616161;
            }

            .course-content-item__info-item {
                display: flex;
                gap: 15px;
                margin-bottom: 18px;

                svg {
                    width: 24px;
                    height: 24px;
                }

                .info-item__text {
                    width: calc(100% - 24px);
                    font-size: 16px;
                    color: #7B7B7B;
                }
            }

            .course-content-item__price {
                color: #616161;
                font-size: 55px;
                text-align: center;
                font-weight: 600;
                margin-bottom: 15px;
            }

            .course-content-item__ul {
                color: #686868;
                font-weight: 600;
                font-size: 20px;
                margin-bottom: 15px;
            }

            .course-content-item__btn {
                width: 100%;
                cursor: pointer;
                padding: 15px 0;
                border-radius: 50px;
                background: linear-gradient(95.06deg, #FEDB99 -32.57%, #FEBB64 95.02%);
                box-shadow: 0px 5px 10px 0px #FEBE694D;
                color: #fff;
                text-transform: uppercase;
                font-size: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                margin-top: auto;

            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .block-course {
        padding-left: 10px;
        padding-right: 10px;
        .course-content {
            display: flex;
            flex-wrap: wrap;

            .course-content-item {
                width: calc((100% - 20px) / 2);
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .block-course {
        .course-title{
            font-size: 30px;
        }
        .course-content {
            display: flex;
            flex-direction: column;

            .course-content-item {
                width: 100%;
            }
        }
    }
}