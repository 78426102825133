.all-block-news {
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.all-news-title {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;

	.all-news-title__title {
		font-size: 40px;
		font-weight: 600;
		font-style: italic;
		color: #5c5c5c;

	}
}

.all-news-content {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

/* Main News */
.all-news-content__main {
	display: flex;
	gap: 20px;
	background-color: #fff;
	border-radius: 16px;
	box-shadow: 0px 4px 50px 0px #9c9c9c33;
	overflow: hidden;
	height: 333.27px;
	width: 100%;
}

.all-main-img {
	flex: 2;
	width: 48%;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.all-main-content {
	flex: 3;
	width: 52%;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

/* Sub News */
.all-news-content__sub {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.all-news-row {
	display: grid;
	grid-template-columns: repeat(3, 1fr); /* 3 items per row */
	gap: 20px;
}

.all-sub-item {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	border-radius: 16px;
	box-shadow: 0px 4px 50px 0px #9c9c9c33;
	overflow: hidden;
}

.all-sub-img {
	height: 200px;
	background-color: #fed780;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.all-main-content {
	padding: 15px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.all-sub-main-img {
	flex: 2;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.all-sub-main-content {
	flex: 3;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.all-content-title {
	font-size: 16px;
	font-weight: bold;
	color: #777777;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* Limit to 2 lines */
	-webkit-box-orient: vertical;
	overflow: hidden;
	word-wrap: break-word; /* Ensure long words break into the next line */
}

.all-content-text {
	font-size: 14px;
	color: #7b7b7b;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.all-content-more-info {
	display: flex;
	justify-content: space-between;
	font-size: 12px;
	color: #a2a2a2;
}
.custom-flex {
	display: flex;
	flex-direction: column;
}
.custom strong {
    font-weight: 500;
}
.custom em {
    font-style: normal;
}
.custom h2 {
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
}
.custom h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}
@media screen and (max-width: 768px) {
	.all-block-news {
		.all-news-content {
			.all-news-row {
				grid-template-columns: 1fr;
			}
		}
	}
}
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    gap: 4px;
}

.pagination-number,
.pagination-arrow {
    min-width: 32px;
    height: 32px;
    border: none;
    background: #fff;
    color: #666;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.pagination-arrow {
    font-size: 20px;
}

.pagination-number:hover:not(.active):not(.dots){
	color: #f5d376;
}
.pagination-arrow:hover:not(:disabled) {
    background: #e6e6e6;
	color: #f5d376;
}

.pagination-number.active {
    color: #fff;
	background: #f5d376;
}

.pagination-number.dots {
    cursor: default;
}

.pagination-arrow:disabled,
.pagination-number:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
.divider-line {
	width: 62px;
	height: 2px;
	border-radius: 20px;
	background-color: #9D9D9D;
}