.block-album {
    margin-top: 40px;
    padding: 0 10px;

    .album-title {
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;

        .album-title__btn {
        }

        .album-title__title {
            font-size: 40px;
            font-weight: 600;
            font-style: italic;
            color: #5c5c5c;
        }
    }

    .album-content {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .item-3-col {
            width: calc((100% - 40px) / 3);
            background-color: #FED780;
            box-shadow: 0px 4px 50px 0px #9C9C9C33;
            border-radius: 15px;
        }
        .item-4-col {
            width: calc((100% - 20px));
            background-color: #FED780;
            box-shadow: 0px 4px 50px 0px #9C9C9C33;
            border-radius: 15px;
        }
        .item-5-col {
            width: calc((100% - 50px)/2);
            background-color: #FED780;
            box-shadow: 0px 4px 50px 0px #9C9C9C33;
            border-radius: 15px;
        }
        .item-2-col {
            width: calc((100% - 20px) / 2);
            background-color: #FED780;
            box-shadow: 0px 4px 50px 0px #9C9C9C33;
            border-radius: 15px;
        }

        .item {
            box-shadow: 0px 4px 50px 0px #9C9C9C33;
            background-color: #FED780;
            border-radius: 15px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 15px;
        }

        .item-full {
            width: 100%;
            background-color: #FED780;
            box-shadow: 0px 4px 50px 0px #9C9C9C33;
            border-radius: 15px;
        }
    }
}

@media screen and (max-width: 768px) {
    .block-album{
        padding-left: 10px;
        padding-right: 10px;
        .album-title{
            .album-title__title{
                font-size: 30px;
                color: #5c5c5c;
            }
        }
    }
}