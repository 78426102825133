/* Container chính */
.all-block-news {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 10px;
}

/* Tiêu đề */
.all-news-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.all-news-title__title {
    font-size: 40px;
    font-weight: 600;
    font-style: italic;
    color: #5c5c5c;
}

/* Nội dung hình ảnh */
.all-news-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.all-news-content__sub {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.all-news-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

/* Ảnh con */
.all-sub-item {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0px 4px 50px 0px #9c9c9c33;
    overflow: hidden;
}

.all-sub-img {
    height: 200px;
    background-color: #fed780;
}

.all-sub-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 16px;
}

/* Responsive */
@media screen and (max-width: 768px) {
    .all-block-news {
        padding-left: 10px;
        padding-right: 10px;
    }

    .all-news-content .all-news-row {
        grid-template-columns: 1fr;
    }

    .all-news-title__title {
        font-size: 30px;
    }
}

/* Pagination giữ nguyên */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    gap: 4px;
}

.pagination-number,
.pagination-arrow {
    min-width: 32px;
    height: 32px;
    border: none;
    background: #fff;
    color: #666;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.pagination-arrow {
    font-size: 20px;
}

.pagination-number:hover:not(.active):not(.dots),
.pagination-arrow:hover:not(:disabled) {
    background: #e6e6e6;
    color: #f5d376;
}

.pagination-number.active {
    color: #fff;
    background: #f5d376;
}

.pagination-number.dots {
    cursor: default;
}

.pagination-arrow:disabled,
.pagination-number:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
