.course-container {
    background-color: #fafafa;
    position: relative;

    .course-bg-top {
        position: absolute;
        top: 0;
        right: 0;

        img {
            height: 600px;
            object-fit: none;
            object-position: bottom;
        }
    }

    .item-swiper__title {
        font-size: 28px;
        font-weight: 900;
        text-transform: uppercase;
        font-style: italic;
        color: #7B7B7B;
        margin-bottom: 20px;
        padding-top: 120px;
        font-weight: bold;
        font-style: italic;
        span {
            color: #ffc600;
        }
    }

    .relative-content {
        position: relative;
        z-index: 2;
    }

.news-content__main{
    margin-top: 50px;
}
}