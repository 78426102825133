img {
    width: 100%;
}
.news-container {
    background-color: #fafafa;
    position: relative;

    .news-bg-top {
        position: absolute;
        top: 0;
        right: 0;

        img {
            height: 600px;
            object-fit: none;
            object-position: bottom;
            opacity: 0.3;
        }
    }

    .item-swiper__title {
        font-size: 28px;
        font-weight: 900;
        text-transform: uppercase;
        font-style: italic;
        color: #7B7B7B;
        margin-bottom: 20px;
        padding-top: 120px;
        font-weight: bold;
        font-style: italic;

        span {
            color: #ffc600;
        }
    }

    .relative-content {
        position: relative;
        z-index: 2;
    }

    .news-content__main {
        margin-top: 50px;
    }

    .single-new-container {
        display: flex;
        gap: 20px;
        min-height: 275px;
        padding: 0 10px;
        justify-content: space-between;

        .single-new__content {

            width: calc(100% - 320px);
            .content__text {
                font-size: 18px;
                color: black;
            }

            .content__title {
                font-size: 30px;
                font-weight: 600;
                margin-bottom: 30px;
            }
        }

        .block-sforum {
            border-radius: 10px;
            box-shadow: rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
            padding: 10px;
            width: 300px;
            position: relative;
            box-sizing: border-box;
            right: 2px;
            background-color: #fff;
            height: fit-content;
        }

        .sforum__title {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 700;
            line-height: 1.2;
            text-align: left;
        }

        .sforum__content {
            .sforum__content-item {
                margin-bottom: 10px;
                cursor: pointer;
                display: flex;
                gap: 10px;

                .content-item__img {
                    height: 64px;
                    margin-right: 10px;
                    border-radius: 10px;
                    min-width: 100px;
                    max-width: 100px;
                }

                .content-item__text {
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    font-size: 14px;
                    line-height: 1.5;
                    color: #444444;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .block-sforum_btn-showmore {
            position: static;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            text-align: center;
            display: block;
            margin-bottom: 0;
            border-radius: 10px;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.91) 50%, #fff 55%);
        }

        .btn-show-more {
            border: none;
            width: 100%;
            max-width: 335px;
            height: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            background: #ffffff;
            font-size: 14px;
            color: #212529;
            text-decoration: none !important;
            cursor: pointer;
            border-radius: 10px;
            box-shadow: rgba(60, 64, 67, 0.1) 0 1px 2px 0, rgba(60, 64, 67, 0.15) 0 2px 6px 2px;

            &:hover {
                color: #d91e18;
                background-color: #fef2f2ff;
                transition: background-color 0.5s ease-out;
                border: 1px solid #d91e18;

                svg {
                    path {
                        fill: #d91e18;
                    }
                }
            }
        }

    }
}

@media screen and (max-width: 800px) {
    .news-container {
        .single-new-container {
            box-sizing: border-box;
            flex-direction: column;

            .block-sforum {
                width: 100%;
            }

            .single-new__content{
                width: 100%;
            }
        }
    }
}