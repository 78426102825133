/* src/components/JerseyComponent.css */
.block-jersey {
    margin-top: 40px;
    padding: 0 10px;
    display: block;
}

.jersey-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.jersey-title__title {
    font-size: 40px;
    font-weight: 600;
    font-style: italic;
    color: #5c5c5c;
}

.jersey-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
}

.jersey-columns {
    display: flex;
    gap: 20px;
    height: 700px; /* or set based on preferred full layout height */
}

.jersey-left,
.jersey-right {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.jersey-left {
    flex: 1;
}

.jersey-right {
    flex: 1;
    justify-content: space-between;
}

.square,
.rect {
    display: block;
    background-color: #FED780;
    box-shadow: 0px 4px 50px 0px #9C9C9C33;
    border-radius: 15px;
    overflow: hidden;
}

.square img,
.rect img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    display: block;
}

.square {
    flex: 1;
    aspect-ratio: 1 / 1;
}

.rect {
    flex: 1;
    aspect-ratio: 3 / 2;
}

@media screen and (max-width: 768px) {
    .block-jersey {
        display: block;
    }
    .jersey-columns {
        flex-direction: column;
        height: auto;
    }
    .jersey-left,
    .jersey-right {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
    }
    .square,
    .rect {
        width: calc(50% - 5px);
        aspect-ratio: 1 / 1;
    }
}

@media screen and (min-width: 769px) {
    .mobile {
        display: none;
    }
}
@media screen and (max-width: 768px) {
    .desktop {
        display: none;
    }
    .mobile .item-full {
        width: 100%;
        height: 192px;
        background-color: #FED780;
        border-radius: 15px;
        overflow: hidden;
        box-shadow: 0px 4px 50px 0px #9C9C9C33;
        margin-bottom: 10px;
    }

    .mobile .item-full img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
