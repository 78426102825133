body {
    margin: 0;
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
    left: 50%;
    position: fixed;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
    z-index: 999;
}

.dp-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.dp-container-video {
    margin-top: 50px;
}

.flex {
    display: flex;
}

.justify-center {
    justify-content: center;
}

.align-center {
    align-items: center;
}

.flex-column {
    flex-direction: column;
}

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.flex-wrap {
    flex-wrap: wrap;
}

.gap-1 {
    gap: 5px;
}

.gap-2 {
    gap: 10px;
}

.gap-3 {
    gap: 15px;
}

.gap-4 {
    gap: 20px;
}

.mb-1 {
    margin-bottom: 5px;
}

.mb-2 {
    margin-bottom: 10px;
}

.mb-3 {
    margin-bottom: 15px;
}

.mb-4 {
    margin-bottom: 20px;
}

.mb-5 {
    margin-bottom: 25px;
}

.mb-6 {
    margin-bottom: 30px;
}

.mb-7 {
    margin-bottom: 35px;
}

.mb-8 {
    margin-bottom: 40px;
}

.mb-9 {
    margin-bottom: 45px;
}

.mb-10 {
    margin-bottom: 50px;
}

.mt-1 {
    margin-top: 5px;
}

.mt-2 {
    margin-top: 10px;
}

.mt-3 {
    margin-top: 15px;
}

.mt-4 {
    margin-top: 20px;
}

.mt-5 {
    margin-top: 25px;
}

.mt-6 {
    margin-top: 30px;
}

.mt-7 {
    margin-top: 35px;
}

.mt-8 {
    margin-top: 40px;
}

.mt-9 {
    margin-top: 45px;
}

.mt-10 {
    margin-top: 50px;
}

.pt-10 {
    padding-top: 50px;
}

.pb-10 {
    padding-bottom: 50px;
}

.w-100 {
    width: 100%;
}

.orange {
    color: #f4a134;
}

.yellow {
    color: #fed880;
}

/*header*/
.dp-header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    height: 80px;
    align-items: center;
    width: 98%;
    background-color: #8E8E8E;
    border-radius: 15px;
    position: relative;
    padding: 0 24px;
    z-index: 2;

    a {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        img {
          height: 65px;
          width: auto;
          padding: 8px;
        }
    }
      
    .trong-dong {
        height: 100%;
        flex: 1;
        display: flex;
        justify-content: center;
        
        img {
            height: 100%;
        }
    }

    .menu {
        position: relative;
        z-index: 3;
    }

    .menu ul {
        list-style: none;
        display: flex;
        gap: 36px;
        height: 100%;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 14px;
        margin-left: 16px;
    }

    .menu li {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .menu a {
        text-decoration: none;
        font-size: 16px;
        color: #ffffff; /* Màu trắng cho chữ */
        transition: all 0.3s ease;
    }

    .menu a:hover {
        color: #f5d376; /* Màu vàng nhạt khi hover */
    }

    .menu a.active {
        color: #f5d376; /* Màu vàng cho trạng thái active */
        /* font-weight: bold; */
    }

    .menu .btn {
        color: #f5d376;
        border: 1.5px solid #f5d376;
        padding: 14px 20px 14px 20px;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s ease;
    }

    .menu .btn:hover {
        background-color: #f5d376;
        color: #ffffff; /* Đổi chữ thành trắng khi hover */
    }

    .btn-dropdown {
        position: relative;
        z-index: 3;

        .dropdown-menu {
            position: absolute;
            box-shadow: 0px 9px 50px 5px #9c9c9c8c;
            right: 0;
            border-radius: 10px;
            background-color: #fff;
            height: 0;
            transition: all .3s ease;
            padding: 0 20px;
            overflow: hidden;

            &.show {
                height: auto;
                box-sizing: border-box;
                padding: 20px;
            }

            &.menu .btn {
                color: #f5d376;
            }

            &.menu .btn:hover {
                background-color: #f5d376;
                color: #ffffff; /* Đổi chữ thành trắng khi hover */
            }

            &.menu a {
                color: #000;
                transition: all 1s ease;
            }

            &.menu a:hover {
                color: #FEDB99; /* Màu vàng nhạt khi hover */
                font-weight: 600;
            }


            &.menu a.active {
                color: #FEDB99; /* Màu vàng cho trạng thái active */
                /* font-weight: bold; */
            }

            ul {
                list-style: none;
                display: flex;
                flex-direction: column;
                padding-left: 0;
                box-sizing: border-box;

                li {
                    box-sizing: border-box;
                    white-space: nowrap;
                    display: block;
                }
            }
        }
    }
}

@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      .dp-header {
        display: flex !important;
        flex-wrap: nowrap !important;
        justify-content: space-between !important;
        align-items: center !important;
      }
    }
  }
  
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
}

/* Show overlay when active */
.modal-overlay.active {
    opacity: 1;
    pointer-events: auto;
}

/* Modal container */
.modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Modal title */
.modal h3 {
    font-size: 20px;
    margin-bottom: 10px;
}

/* Modal content text */
.modal p {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
    margin-bottom: 20px;
}

/* Action buttons */
.modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.modal-buttons a {
    text-decoration: none;
    color: #007bff;
    font-size: 16px;
    padding: 8px 12px;
    border: 1px solid #007bff;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.modal-buttons a:hover {
    background-color: #007bff;
    color: white;
}

/* Close button */
.close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 20px;
    color: #333;
    cursor: pointer;
}

@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) {
      .dp-header {
        display: flex !important;
        flex-wrap: nowrap !important;
      }
    }
  }

  
/*footer*/
footer {
    background: #8E8E8E;
}

.footer-container {
    margin: 20px 0;

    .footer-title {
        text-transform: uppercase;
        font-size: 24px;
        font-weight: 600;
        color: #fff;
        padding: 40px 10px;
        border-bottom: 1px #fff solid;
    }

    .footer-content {
        padding: 40px 0;
        border-bottom: 1px #fff solid;
        display: flex;
        align-items: center;
        gap: 80px;

        .logo {
            width: 200px;
            height: 200px;
        }

        .list-address {
            width: calc(100% - 280px);
            display: flex;
            flex-wrap: wrap;
            gap: 50px;

            .address-item {
                width: calc((100% - 100px) / 3);
                display: flex;
                flex-direction: column;
                gap: 20px;

                .address-item__title {
                    display: flex;
                    gap: 5px;
                    font-size: 24px;
                    font-weight: 600;
                    color: #fff;
                }

                .address-item__content {
                    color: #fff;
                    font-size: 14px;
                    line-height: 28px;
                }
            }
        }
    }

    .footer-bot {
        padding: 40px 0;
        display: flex;
        justify-content: space-between;

        .footer-bot__cpr {
            font-size: 16px;
            font-weight: 600;
            color: #fff;
        }

        .footer-bot__nav {
            display: flex;
            gap: 20px;

            .nav__item {
                font-size: 14px;
                color: #fff;
                text-decoration: unset;
                display: block;
                white-space: nowrap;
                font-weight: 600;
            }
        }
    }
}

/* Custom CSS for the grid */
.custom-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    position: fixed;
    bottom: 20rem;
    right: 0px;
    background-color: transparent;
    /*box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);*/
    justify-content: center;
    z-index: 9999;
}

/* Wrapper for individual icons */
.icon-wrapper {
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

/* Styling for the images */
.icon-image {
    width: 50%; /* Equivalent to w-1/2 */
    margin: 0.75rem; /* Equivalent to m-3 */
    border-radius: 50%; /* Equivalent to rounded-full */
    clear: both; /* Ensures clear layout */
}

/* Status indicator wrapper */
.status-indicator {
    position: absolute;
    right: 1rem;
    top: 0.6rem;
    display: inline-flex;
}

/* Status dots */
.status-dot {
    width: 12px; /* Equivalent to w-3 */
    height: 12px; /* Equivalent to h-3 */
    background-color: #10b981; /* Equivalent to bg-green-500 */
    border-radius: 50%; /* Makes it circular */
    position: absolute;
}

.status-dot.animate-ping {
    animation: ping 1.5s infinite;
}

.status-dot.animate-pulse {
    animation: pulse 1.5s infinite;
}

/* Animations */
@keyframes ping {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    75%, 100% {
        transform: scale(2);
        opacity: 0;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0.5;
    }
}



main {
    margin-top: 80px;
}

.mobile {
    display: none;
}

.desktop {
    display: block;
}

a {
    text-decoration: unset;
}

.btn-dp {
    cursor: pointer;
    color: #5C5C5C;
    padding: 10px 30px;
    text-decoration: unset;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    box-shadow: 0 5px 10px 0 #FFD35C4D;
    background: linear-gradient(95.06deg, #FEDB99 -32.57%, #FED153 95.02%);
    border-radius: 45px;
    font-weight: 600;
}

.loader-container {
    display: flex;
    min-height: 300px;
    justify-content: center;
    align-items: center;
}

.loader {
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #FED153;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 1200px) {
    .dp-header {
        justify-content: space-between;
        z-index: 2;
        padding-left: 25px;
        padding-right: 25px;

        .trong-dong {
            position: absolute;
            z-index: 1;
            height: 100%;
            opacity: 0.6;
            left: 80px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .footer-container {
        padding-left: 10px;
        padding-right: 10px;

        .footer-title {
            font-size: 18px;
        }

        .footer-content {
            flex-direction: column;

            .list-address {
                width: 100%;

                .address-item {
                    width: 100%;
                }
            }

            .logo {
                order: 2;
            }
        }

        .footer-bot {
            flex-direction: column;

            .footer-bot__cpr {
                padding-bottom: 40px;
                border-bottom: 1px #fff solid;
                text-align: center;
            }

            .footer-bot__nav {
                padding: 40px 0;
                justify-content: space-around;
            }
        }
    }

    .mobile {
        display: block;
    }

    .desktop {
        display: none;
    }
}

